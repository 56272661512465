(function () {
  'use strict';

  angular.module('core.services', [
    'services.address',
    'services.alert',
    'services.analytics',
    'services.animate',
    'services.cart',
    'services.catalog',
    'services.contentful',
    'services.cookies',
    'services.date',
    'services.global',
    'services.homeNavigation',
    'services.log',
    'services.metaTag',
    'services.navigation',
    'services.order',
    'services.payment',
    'services.product',
    'services.rating',
    'services.scroll',
    'services.store',
    'services.user',
    'services.billing',
    'services.forkCard',

  ]);
})();

(function () {
  'use strict';

  angular.module('services.forkCard').service('ForkCardService', ForkCardService);

  function ForkCardService($http, config) {

    return {
      getHistoryCard : getHistoryCard
    };

    function getHistoryCard(user_id) {
      //historial_ventas/usuario/200591
      return $http.get(`${config.URL_API}/historial_ventas/usuario/${user_id}`);
    }

    
  }
})();

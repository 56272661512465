(function () {
  'use strict';

  angular
    .module('app')
    .config([
      '$httpProvider',
      '$locationProvider',
      '$stateProvider',
      '$urlMatcherFactoryProvider',
      '$urlRouterProvider',
      'config',
      'contentfulProvider',
      'lazyImgConfigProvider',
      'uiMask.ConfigProvider',
      'ZendeskWidgetProvider',
      AppConfig,
    ]);

  function AppConfig(
    $httpProvider,
    $locationProvider,
    $stateProvider,
    $urlMatcherFactoryProvider,
    $urlRouterProvider,
    config,
    contentfulProvider,
    lazyImgConfigProvider,
    uiMaskConfigProvider,
    ZendeskWidgetProvider
  ) {
    $httpProvider.interceptors.push('HttpInterceptorFactory');

    /**
     * Eliminar el #! de la ruta.
     * @see https://docs.angularjs.org/api/ng/provider/$locationProvider
     */
    $locationProvider.hashPrefix('');

    /**
     * Configuración de Contentful mediante el provider de angular-contenful.
     * @see https://github.com/jvandemo/angular-contentful
     */
    contentfulProvider.setOptions({
      space: 'ct4yzo5ssbr6',
      accessToken: '59487deb85b7f6cb82253f118cd5eccb038d84b2af20214988f9b0155fc2f331',
    });

    /**
     * Configuración del widget de Zendesk.
     * @see https://github.com/CrossLead/angular-zendesk-widget#settings
     */
    ZendeskWidgetProvider.init({
      accountUrl: 'forkchile.zendesk.com',
      beforePageLoad: function (zE) {
        zE.setLocale('es');
      },
    });

    /**
     * Configuración global de angular-ui-mask.
     * @see https://github.com/angular-ui/ui-mask#global-customization
     */
    uiMaskConfigProvider.clearOnBlur(false);

    /**
     * Solución del bug del scrollbar cuando se abre un segundo modal y se cierra el primero
     * @see https://stackoverflow.com/questions/19305821/multiple-modals-overlay
     */
    $(document).on('hidden.bs.modal', '.modal', function () {
      var modalVisible = $('.modal:visible').length;
      if (modalVisible) {
        $(document.body).addClass('modal-open');
      }
      if (!modalVisible) {
        $(document.body).css('padding-right', '0');
      }
    });

    /**
     * Redirecciones
     * @see https://github.com/angular-ui/ui-router/wiki/URL-Routing#urlrouterprovider
     */
    $urlRouterProvider.when('', '/');

    // Hacer más amigable algunas rutas.
    // Estas no pueden cambiar, debido a que son accesibles desde fuentes externas y no sólamente desde el ecommerce
    $urlRouterProvider.when('/recover-password', '/recuperar-contrasena/email');
    $urlRouterProvider.when(
      '/usuario/nueva-password/{user_id}/{email}/{token}',
      '/recuperar-contrasena/nueva/{user_id}/{email}/{token}'
    );

    // Animar las imágenes que se cargan con la directiva lazy-img
    lazyImgConfigProvider.setOptions({
      onSuccess: function (image) {
        var element = image.$elem[0];
        element.classList.add('animated', 'fadeIn');
        element.addEventListener('animationend', function () {
          element.classList.remove('animated', 'fadeIn');
        });
      },
    });

    /**
     * Función que verifica si el usuario está autenticado. Si no lo está, no puede acceder a ciertas rutas
     * del ecommerce
     * @type {*[]}
     */
    var userLoggedIn = [
      '$cookies',
      '$q',
      '$state',
      '$timeout',
      'UserService',
      function ($cookies, $q, $state, $timeout, UserService) {
        var defer = $q.defer();
        var userAuth = $cookies.get(config.AUTH_TOKEN);

        if (!userAuth) {
          $timeout(function () {
            $state.transitionTo('home');
            defer.reject();
          });
        } else {
          UserService.getCurrentUserData().then(
            function (value) {
              if (value.data.email) {
                defer.resolve();
              } else {
                $state.transitionTo('home');
                defer.reject();
              }
            },
            function () {
              $state.transitionTo('home');
              defer.reject();
            }
          );
        }

        return defer.promise;
      },
    ];

    /**
     * Función que verifica si el token en la ruta corresponde al correo y al número identificador del usuario.
     * Si no lo está, no debería acceder a ciertas rutas delicadas del ecommerce
     * @type {*[]}
     */
    var emailToken = [
      '$q',
      '$state',
      '$stateParams',
      '$timeout',
      'UserService',
      function ($q, $state, $stateParams, $timeout, UserService) {
        var id = $stateParams.user_id;
        var email = $stateParams.email;
        var token = $stateParams.token;

        var defer = $q.defer();

        UserService.checkRecoverPassword(id, email, token).then(
          function () {
            defer.resolve();
          },
          function () {
            $timeout(function () {
              $state.transitionTo('home');
              defer.reject();
            });
          }
        );

        return defer.promise;
      },
    ];

    /**
     * Configurar los estados dentro de la aplicación
     * @see https://github.com/angular-ui/ui-router/wiki#state-manager
     */

    /**
     * Home
     */
    $stateProvider
      .state({
        name: 'home',
        url: '/',
        templateUrl: 'pages/home/home.template.html',
        controller: 'HomeController as vm',
      })
      .state({
        name: 'home.category',
        url: 'categoria/{categoryId}/{categoryName}',
        templateUrl: 'pages/home/home.template.html',
        controller: 'HomeController as vm',
        params: {
          categoryId: { dynamic: true },
          categoryName: { dynamic: true },
        },
      })
      .state({
        name: 'home.onboarding',
        url: 'onboarding/{addressId}',
        templateUrl: 'pages/home/home.template.html',
        controller: 'HomeController as vm',
      })
      .state({
        name: 'home.forkPiggyBank',
        url: 'alcancia-fork',
        templateUrl: 'pages/home/home.template.html',
        controller: 'HomeController as vm',
      })
      .state({
        name: 'home.help',
        url: 'ayuda',
        templateUrl: 'pages/home/home.template.html',
        controller: 'HomeController as vm',
      })
      .state({
        name: 'home.pwa',
        url: 'pwa',
        templateUrl: 'pages/home/home.template.html',
        controller: 'HomeController as vm',
      }).state({
        name: 'home.register',
        url: 'register',
        templateUrl: 'shared/login-modal/components/register-form/register-form.template.html',
        controller: 'RegisterFormController as vm',
      })

      /**
       * Producto / Regular y Pack
       */
      .state({
        name: 'productDetails',
        url: '/producto/{productId}/{productName}',
        templateUrl: 'pages/product-details/product-details.template.html',
        controller: 'ProductDetailsController as vm',
      })

      /**
       * Checkout
       */
      .state({
        name: 'checkout',
        url: '/checkout/{stepName}?status&message',
        templateUrl: 'pages/checkout/checkout.template.html',
        controller: 'CheckoutController as vm',
        params: {
          stepName: {
            dynamic: true,
          },
        },
      })

      /**
       * Usuario
       */
      .state({
        name: 'user',
        url: '/usuario',
        templateUrl: 'pages/user-profile/user-profile.template.html',
        controller: 'UserProfileController as vm',
        abstract: true,
        resolve: { authenticate: userLoggedIn },
      })
      .state({
        name: 'user.profile',
        url: '/perfil',
        templateUrl: 'pages/user-profile/pages/user-data/user-data.template.html',
        controller: 'UserDataController as vm',
      })
      .state({
        name: 'user.addresses',
        url: '/direcciones',
        templateUrl: 'pages/user-profile/pages/addresses/addresses.template.html',
        controller: 'AddressesController as vm',
      }).state({
        name: 'user.forkCard',
        url: '/forkCard',
        templateUrl: 'pages/user-profile/pages/forkCard/forkCard.template.html',
        controller: 'ForkCardController as vm',
      })
      .state({
        name: 'user.billing',
        url: '/billing',
        templateUrl: 'pages/user-profile/pages/billing/billing.template.html',
        controller: 'BillingController as vm',
      })
      .state({
        name: 'user.payments',
        url: '/pagos?status&message&freezer',
        templateUrl: 'pages/user-profile/pages/credit-card/credit-card.template.html',
        controller: 'CreditCardController as vm',
      })
      .state({
        name: 'user.orders',
        url: '/pedidos',
        templateUrl: 'pages/user-profile/pages/orders/orders.template.html',
        controller: 'OrdersController as vm',
      })
      .state({
        name: 'user.piggyBank',
        url: '/alcancia',
        templateUrl: 'pages/user-profile/pages/piggy-bank/piggy-bank.template.html',
        controller: 'PiggyBankController as vm',
      })

      /**
       * Recuperar contraseña
       */
      .state({
        name: 'recoverPassword',
        url: '/recuperar-contrasena',
        templateUrl: 'pages/recover-password/recover-password.template.html',
        controller: 'RecoverPasswordController as vm',
        abstract: true,
      })
      .state({
        name: 'recoverPassword.emailInput',
        url: '/email',
        templateUrl: 'pages/recover-password/pages/email-input/email-input.template.html',
        controller: 'EmailInputController as vm',
      })
      .state({
        name: 'recoverPassword.newPassword',
        url: '/nueva/{user_id}/{email}/{token}',
        templateUrl: 'pages/recover-password/pages/new-password/new-password.template.html',
        controller: 'NewPasswordController as vm',
        resolve: { authenticate: emailToken },
      })
      .state({
        name: 'purchaseSuccess',
        url: '/comprobante/exito/{orderId}',
        templateUrl: 'pages/purchase-succeed/purchase-succeed.template.html',
        controller: 'PurchaseSucceedController as vm',
      })
      .state({
        name: 'purchaseErrorPaymentMethod',
        url: '/comprobante/rechazo/{paymentMethod}/{orderId}',
        templateUrl: 'pages/purchase-failed/purchase-failed.template.html',
        controller: 'PurchaseFailedController as vm',
      })
      .state({
        name: 'purchaseError',
        url: '/comprobante/rechazo/{paymentMethod}/{orderId}',
        templateUrl: 'pages/purchase-failed/purchase-failed.template.html',
        controller: 'PurchaseFailedController as vm',
      })
      .state({
        name: 'timeout',
        url: '/comprobante/timeout/{orderId}/{token}',
        templateUrl: 'pages/transbank-timeout/transbank-timeout.template.html',
        controller: 'TransbankTimeoutController as vm',
      })
      .state({
        name: 'rating',
        url: '/evaluar-pedido/{orderId}?hash&source&score&ventaDigital',
        templateUrl: 'pages/external-rating/external-rating.template.html',
        controller: 'ExternalRatingController as vm',
      })
      .state({
        name: 'rating-without-score',
        url: '/:orderId/:hash/:score',
        templateUrl: 'pages/external-rating/external-rating.template.html',
        controller: 'ExternalRatingController as vm',
      })
      // Activar cuenta usuario nuevo
      .state({
        name: 'activate-account',
        url: '/activar-cuenta/:hash',
        templateUrl: 'pages/activate-account/activate-account.template.html',
        controller: 'ActivateAccountController as vm',
      })
      /**
       * Error
       */
      .state('404client', {
        url: '*path',
        templateUrl: 'pages/error/404.html',
      })
    /**
     * Manipula el historial del navegador usando un script
     * @see https://docs.angularjs.org/api/ng/provider/$locationProvider#html5Mode
     */
    $locationProvider.html5Mode(true);
  }
})();
